import React from 'react'
import { PushNotificationBodyContent, PushNotificationBodyContentProps } from '../../components/PushNotification/PushNotificationBody/PushNotificationBodyContent'
import PushNotificationBodyImage, { PushNotificationBodyImageProps } from '../../components/PushNotification/PushNotificationBody/PushNotificationBodyImage'
import PushNotificationHeader from '../../components/PushNotification/PushNotificationHeader'

interface PushNotificationBodyProps{
    pushNotificationBodyImageProps: PushNotificationBodyImageProps
    pushNotificationBodyContentProps: PushNotificationBodyContentProps
}

const PushNotification: React.FC<PushNotificationBodyProps> = ({
  pushNotificationBodyImageProps,
  pushNotificationBodyContentProps,
}) => {
  return (
    <div id="toast-notification" className={`h-full w-full`} role="alert">
      {/* Header */}
      <PushNotificationHeader>New Notification</PushNotificationHeader>
      {/* Body */}
      <div className="flex items-center">
        {/* Push Notification Body Image */}
        <PushNotificationBodyImage {...pushNotificationBodyImageProps} />
        {/* Push Notification Body Content */}
        <PushNotificationBodyContent {...pushNotificationBodyContentProps} />
      </div>
    </div>
  )
}

export default PushNotification
