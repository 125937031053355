import React from 'react'

export interface PushNotificationHeaderProps {}

const PushNotificationHeader: React.FC<PushNotificationHeaderProps> = ({
  children,
}) => {
  return (
    <div className="flex items-center mb-3">
      <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">
        {children}
      </span>
    </div>
  )
}

export default PushNotificationHeader
