import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ActiveUsersResponse = {
  __typename?: 'ActiveUsersResponse';
  errors?: Maybe<SuccessError>;
  timeSeriesData?: Maybe<Array<DataPoint>>;
};

export type AllBlogResponse = {
  __typename?: 'AllBlogResponse';
  blogs?: Maybe<Blogs>;
};

export type AllPostResponse = {
  __typename?: 'AllPostResponse';
  user?: Maybe<User>;
  post?: Maybe<Post>;
};

export type BlogResponse = {
  __typename?: 'BlogResponse';
  blog?: Maybe<Blogs>;
  error?: Maybe<SuccessError>;
};

export type Blogs = {
  __typename?: 'Blogs';
  id?: Maybe<Scalars['String']>;
  blog_picture?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
};

export type BlogsInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  blog_picture: Scalars['String'];
  content: Scalars['String'];
};

export type CreateUserRecordInput = {
  uid?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
};

export type DashboardResponse = {
  __typename?: 'DashboardResponse';
  errors?: Maybe<SuccessError>;
  totalUsers?: Maybe<Scalars['Float']>;
  newUsers?: Maybe<Scalars['Float']>;
  totalPosts?: Maybe<Scalars['Float']>;
  totalVenues?: Maybe<Scalars['Float']>;
  timeSeriesData?: Maybe<Array<DataPoint>>;
};

export type DataPoint = {
  __typename?: 'DataPoint';
  x: Scalars['String'];
  y: Scalars['Float'];
};

export type DeleteUserResponse = {
  __typename?: 'DeleteUserResponse';
  errors?: Maybe<SuccessError>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export enum FilterByDate {
  Today = 'Today',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days'
}

export type FilterByDateInput = {
  filter: FilterByDate;
};

export type Like = {
  __typename?: 'Like';
  id: Scalars['String'];
  likeId?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<UserRecord>;
};

export type MutateBlogResponse = {
  __typename?: 'MutateBlogResponse';
  id?: Maybe<Scalars['String']>;
  error?: Maybe<SuccessError>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateUser: UserUpdateResponse;
  updateDisabledProperty: UserResponse;
  deleteUser: DeleteUserResponse;
  setAdmin: UserResponse;
  createUserRecord: UserResponse;
  updateUserRecord: UserResponse;
  toggleDisabled: ToggleResponse;
  loginAdmin: LoginResponse;
  logout: Scalars['Boolean'];
  deletePost: Scalars['Boolean'];
  updatePost: UpdatePostResponse;
  updateFeatured: UpdateFeaturedResponse;
  createBlog: MutateBlogResponse;
  updateBlog: MutateBlogResponse;
  deleteBlog: MutateBlogResponse;
};


export type MutationUpdateUserArgs = {
  properties: UpdateUserInput;
};


export type MutationUpdateDisabledPropertyArgs = {
  inputs: UpdateDisableInput;
};


export type MutationDeleteUserArgs = {
  uid: Scalars['String'];
};


export type MutationSetAdminArgs = {
  options: SetAdminInput;
};


export type MutationCreateUserRecordArgs = {
  properties: CreateUserRecordInput;
};


export type MutationUpdateUserRecordArgs = {
  properties: UpdateUserRecordInput;
};


export type MutationToggleDisabledArgs = {
  options: ToggleDisabledUserRecord;
};


export type MutationLoginAdminArgs = {
  options: UserNamePasswordInput;
};


export type MutationLogoutArgs = {
  token: Scalars['String'];
};


export type MutationDeletePostArgs = {
  id: Scalars['String'];
};


export type MutationUpdatePostArgs = {
  args: UpdatePostArgs;
};


export type MutationUpdateFeaturedArgs = {
  post: UpdateFeatured;
};


export type MutationCreateBlogArgs = {
  blog: BlogsInput;
};


export type MutationUpdateBlogArgs = {
  blog: BlogsInput;
};


export type MutationDeleteBlogArgs = {
  id: Scalars['String'];
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Scalars['String']>>;
  dateISO?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  openingHours?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profilePictureBKG?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  timestampInv?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  pins?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Like>>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredPriority?: Maybe<Scalars['Float']>;
};

export type PostResponse = {
  __typename?: 'PostResponse';
  errors?: Maybe<SuccessError>;
  post?: Maybe<Post>;
};

export type PostsResponse = {
  __typename?: 'PostsResponse';
  errors?: Maybe<SuccessError>;
  posts?: Maybe<Array<Post>>;
};

export type Query = {
  __typename?: 'Query';
  users: Array<User>;
  getUser: UserFsResponse;
  getActiveUser: UsersResponse;
  userRecords: Array<UserRecord>;
  userRecordFindByUid: UserResponse;
  me?: Maybe<UserRecord>;
  getWishers: WishersResponse;
  getAllPosts: Array<AllPostResponse>;
  getAllFeaturedPosts: Array<AllPostResponse>;
  getPost: PostResponse;
  getUserPost: PostsResponse;
  getDashboard: DashboardResponse;
  getActiveUsers: ActiveUsersResponse;
  getAllBlogs: Array<AllBlogResponse>;
  getBlog: BlogResponse;
};


export type QueryGetUserArgs = {
  uid: Scalars['String'];
};


export type QueryUserRecordFindByUidArgs = {
  options: UidInput;
};


export type QueryMeArgs = {
  token: Scalars['String'];
};


export type QueryGetWishersArgs = {
  id: Scalars['String'];
};


export type QueryGetPostArgs = {
  uid: Scalars['String'];
};


export type QueryGetUserPostArgs = {
  id: Scalars['String'];
};


export type QueryGetActiveUsersArgs = {
  filter: FilterByDateInput;
};


export type QueryGetBlogArgs = {
  id: Scalars['String'];
};

export type SetAdminInput = {
  uid: Scalars['String'];
};

export type SuccessError = {
  __typename?: 'SuccessError';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type ToggleDisabledUserRecord = {
  uids: Array<UidInput>;
};

export type ToggleResponse = {
  __typename?: 'ToggleResponse';
  errors?: Maybe<SuccessError>;
  users?: Maybe<Array<UserRecord>>;
};

export type UidInput = {
  uid: Scalars['String'];
};

export type UpdateDisableInput = {
  uid: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type UpdateFeatured = {
  id: Scalars['String'];
  isFeatured?: Maybe<Scalars['Boolean']>;
};

export type UpdateFeaturedResponse = {
  __typename?: 'UpdateFeaturedResponse';
  errors?: Maybe<SuccessError>;
  post?: Maybe<Post>;
};

export type UpdatePostArgs = {
  id?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Scalars['String']>>;
  dateISO?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  openingHours?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profilePictureBKG?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  timestampInv?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  pins?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  featuredPriority?: Maybe<Scalars['Float']>;
};

export type UpdatePostResponse = {
  __typename?: 'UpdatePostResponse';
  errors?: Maybe<Array<FieldError>>;
  post?: Maybe<Post>;
};

export type UpdateUserInput = {
  id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  businessGallery?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Scalars['String']>>;
  isVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profilePictureBKG?: Maybe<Scalars['String']>;
  relationshipStatus?: Maybe<Scalars['String']>;
  stoops?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  wishes?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  openingHours?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['Float']>;
  company_email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
};

export type UpdateUserRecordInput = {
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  businessGallery?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Scalars['String']>>;
  isVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profilePictureBKG?: Maybe<Scalars['String']>;
  relationshipStatus?: Maybe<Scalars['String']>;
  stoops?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  wishes?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  openingHours?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  companyAddress?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['Float']>;
  company_email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
};

export type UserFsResponse = {
  __typename?: 'UserFsResponse';
  errors?: Maybe<SuccessError>;
  user?: Maybe<User>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  uid: Scalars['String'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  photoURL: Scalars['String'];
  providerId: Scalars['String'];
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  creationTime: Scalars['String'];
  lastSignInTime: Scalars['String'];
  lastRefreshTime?: Maybe<Scalars['String']>;
};

export type UserNamePasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserRecord = {
  __typename?: 'UserRecord';
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  metadata?: Maybe<UserMetadata>;
  password?: Maybe<Scalars['String']>;
  providerData: Array<UserInfo>;
  passwordHash?: Maybe<Scalars['String']>;
  passwordSalt?: Maybe<Scalars['String']>;
  tokensValidAfterTime?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

export type UserUpdateResponse = {
  __typename?: 'UserUpdateResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  errors?: Maybe<Array<FieldError>>;
  users?: Maybe<Array<User>>;
};

export type WishersResponse = {
  __typename?: 'WishersResponse';
  errors?: Maybe<SuccessError>;
  users?: Maybe<Array<User>>;
};

export type CreateBlogMutationVariables = Exact<{
  title: Scalars['String'];
  blog_picture: Scalars['String'];
  content: Scalars['String'];
}>;


export type CreateBlogMutation = (
  { __typename?: 'Mutation' }
  & { createBlog: (
    { __typename?: 'MutateBlogResponse' }
    & Pick<MutateBlogResponse, 'id'>
    & { error?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type CreateUserRecordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}>;


export type CreateUserRecordMutation = (
  { __typename?: 'Mutation' }
  & { createUserRecord: (
    { __typename?: 'UserResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'uid' | 'email' | 'username' | 'name' | 'type' | 'disabled'>
    )> }
  ) }
);

export type DeleteBlogMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteBlogMutation = (
  { __typename?: 'Mutation' }
  & { deleteBlog: (
    { __typename?: 'MutateBlogResponse' }
    & { error?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type DeletePostMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePost'>
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'DeleteUserResponse' }
    & { errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type LoginAdminMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginAdminMutation = (
  { __typename?: 'Mutation' }
  & { loginAdmin: (
    { __typename?: 'LoginResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, user?: Maybe<(
      { __typename?: 'UserRecord' }
      & Pick<UserRecord, 'email' | 'uid'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ToggleDisabledMutationVariables = Exact<{
  uids: ToggleDisabledUserRecord;
}>;


export type ToggleDisabledMutation = (
  { __typename?: 'Mutation' }
  & { toggleDisabled: (
    { __typename?: 'ToggleResponse' }
    & { errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'message' | 'success'>
    )>, users?: Maybe<Array<(
      { __typename?: 'UserRecord' }
      & Pick<UserRecord, 'uid' | 'email' | 'disabled'>
    )>> }
  ) }
);

export type UpdateBlogMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
  blog_picture: Scalars['String'];
  content: Scalars['String'];
}>;


export type UpdateBlogMutation = (
  { __typename?: 'Mutation' }
  & { updateBlog: (
    { __typename?: 'MutateBlogResponse' }
    & { error?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type UpdateDisabledPropertyMutationVariables = Exact<{
  inputs: UpdateDisableInput;
}>;


export type UpdateDisabledPropertyMutation = (
  { __typename?: 'Mutation' }
  & { updateDisabledProperty: (
    { __typename?: 'UserResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'uid' | 'email' | 'username' | 'name' | 'type' | 'disabled'>
    )> }
  ) }
);

export type UpdateFeaturedMutationVariables = Exact<{
  id: Scalars['String'];
  isFeatured: Scalars['Boolean'];
}>;


export type UpdateFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { updateFeatured: (
    { __typename?: 'UpdateFeaturedResponse' }
    & { errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type UpdatePostMutationVariables = Exact<{
  args: UpdatePostArgs;
}>;


export type UpdatePostMutation = (
  { __typename?: 'Mutation' }
  & { updatePost: (
    { __typename?: 'UpdatePostResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'website' | 'instagram' | 'contact' | 'title' | 'address' | 'comment' | 'imageURL' | 'location' | 'categories' | 'openingHours' | 'isFeatured' | 'featuredPriority'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  properties: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserUpdateResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'uid' | 'email' | 'name' | 'username' | 'location' | 'phone' | 'profilePicture' | 'profilePictureBKG' | 'dob' | 'relationshipStatus' | 'gender'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  ) }
);

export type GetActiveUsersQueryVariables = Exact<{
  filter: FilterByDate;
}>;


export type GetActiveUsersQuery = (
  { __typename?: 'Query' }
  & { getActiveUsers: (
    { __typename?: 'ActiveUsersResponse' }
    & { timeSeriesData?: Maybe<Array<(
      { __typename?: 'DataPoint' }
      & Pick<DataPoint, 'x' | 'y'>
    )>>, errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type GetBlogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBlogsQuery = (
  { __typename?: 'Query' }
  & { getAllBlogs: Array<(
    { __typename?: 'AllBlogResponse' }
    & { blogs?: Maybe<(
      { __typename?: 'Blogs' }
      & Pick<Blogs, 'id' | 'blog_picture' | 'title' | 'content' | 'created_at'>
    )> }
  )> }
);

export type GetAllFeaturedPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllFeaturedPostsQuery = (
  { __typename?: 'Query' }
  & { getAllFeaturedPosts: Array<(
    { __typename?: 'AllPostResponse' }
    & { post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'website' | 'title' | 'address' | 'comment' | 'imageURL' | 'author' | 'isFeatured' | 'featuredPriority'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )> }
  )> }
);

export type GetAllPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPostsQuery = (
  { __typename?: 'Query' }
  & { getAllPosts: Array<(
    { __typename?: 'AllPostResponse' }
    & { post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'website' | 'title' | 'address' | 'comment' | 'imageURL' | 'author' | 'isFeatured' | 'featuredPriority'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )> }
  )> }
);

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'uid' | 'email' | 'username' | 'name' | 'type' | 'disabled' | 'isAdmin'>
  )> }
);

export type GetBlogQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBlogQuery = (
  { __typename?: 'Query' }
  & { getBlog: (
    { __typename?: 'BlogResponse' }
    & { blog?: Maybe<(
      { __typename?: 'Blogs' }
      & Pick<Blogs, 'blog_picture' | 'title' | 'content' | 'created_at'>
    )>, error?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type GetDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDashboardQuery = (
  { __typename?: 'Query' }
  & { getDashboard: (
    { __typename?: 'DashboardResponse' }
    & Pick<DashboardResponse, 'totalUsers' | 'totalPosts' | 'totalVenues' | 'newUsers'>
    & { timeSeriesData?: Maybe<Array<(
      { __typename?: 'DataPoint' }
      & Pick<DataPoint, 'x' | 'y'>
    )>>, errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'message' | 'success'>
    )> }
  ) }
);

export type GetPostQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetPostQuery = (
  { __typename?: 'Query' }
  & { getPost: (
    { __typename?: 'PostResponse' }
    & { post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'website' | 'instagram' | 'contact' | 'title' | 'address' | 'comment' | 'imageURL' | 'location' | 'categories' | 'openingHours' | 'isFeatured' | 'featuredPriority'>
      & { likes?: Maybe<Array<(
        { __typename?: 'Like' }
        & Pick<Like, 'id' | 'likeId'>
      )>> }
    )>, errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserFsResponse' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'uid' | 'email' | 'name' | 'username' | 'location' | 'phone' | 'profilePicture' | 'profilePictureBKG' | 'dob' | 'relationshipStatus' | 'gender' | 'type'>
    )>, errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type GetUserPostQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserPostQuery = (
  { __typename?: 'Query' }
  & { getUserPost: (
    { __typename?: 'PostsResponse' }
    & { posts?: Maybe<Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'website' | 'title' | 'address' | 'comment' | 'imageURL'>
    )>>, errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type GetWishersQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetWishersQuery = (
  { __typename?: 'Query' }
  & { getWishers: (
    { __typename?: 'WishersResponse' }
    & { users?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'uid' | 'email'>
    )>>, errors?: Maybe<(
      { __typename?: 'SuccessError' }
      & Pick<SuccessError, 'success' | 'message'>
    )> }
  ) }
);

export type MeQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserRecord' }
    & Pick<UserRecord, 'uid' | 'email'>
  )> }
);

export type UserRecordFindByUidQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type UserRecordFindByUidQuery = (
  { __typename?: 'Query' }
  & { userRecordFindByUid: (
    { __typename?: 'UserResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'disabled'>
    )> }
  ) }
);

export type UserRecordsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRecordsQuery = (
  { __typename?: 'Query' }
  & { userRecords: Array<(
    { __typename?: 'UserRecord' }
    & Pick<UserRecord, 'uid' | 'photoUrl' | 'email' | 'disabled' | 'passwordHash'>
    & { metadata?: Maybe<(
      { __typename?: 'UserMetadata' }
      & Pick<UserMetadata, 'creationTime'>
    )>, providerData: Array<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'uid' | 'providerId'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'uid' | 'email' | 'type'>
    )> }
  )> }
);


export const CreateBlogDocument = gql`
    mutation createBlog($title: String!, $blog_picture: String!, $content: String!) {
  createBlog(blog: {title: $title, blog_picture: $blog_picture, content: $content}) {
    id
    error {
      success
      message
    }
  }
}
    `;
export type CreateBlogMutationFn = Apollo.MutationFunction<CreateBlogMutation, CreateBlogMutationVariables>;

/**
 * __useCreateBlogMutation__
 *
 * To run a mutation, you first call `useCreateBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlogMutation, { data, loading, error }] = useCreateBlogMutation({
 *   variables: {
 *      title: // value for 'title'
 *      blog_picture: // value for 'blog_picture'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateBlogMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlogMutation, CreateBlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlogMutation, CreateBlogMutationVariables>(CreateBlogDocument, options);
      }
export type CreateBlogMutationHookResult = ReturnType<typeof useCreateBlogMutation>;
export type CreateBlogMutationResult = Apollo.MutationResult<CreateBlogMutation>;
export type CreateBlogMutationOptions = Apollo.BaseMutationOptions<CreateBlogMutation, CreateBlogMutationVariables>;
export const CreateUserRecordDocument = gql`
    mutation CreateUserRecord($email: String!, $password: String!, $phone: String, $name: String) {
  createUserRecord(properties: {email: $email, password: $password, phoneNumber: $phone, name: $name}) {
    errors {
      field
      message
    }
    user {
      uid
      email
      username
      name
      type
      disabled
    }
  }
}
    `;
export type CreateUserRecordMutationFn = Apollo.MutationFunction<CreateUserRecordMutation, CreateUserRecordMutationVariables>;

/**
 * __useCreateUserRecordMutation__
 *
 * To run a mutation, you first call `useCreateUserRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRecordMutation, { data, loading, error }] = useCreateUserRecordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      phone: // value for 'phone'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserRecordMutation, CreateUserRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserRecordMutation, CreateUserRecordMutationVariables>(CreateUserRecordDocument, options);
      }
export type CreateUserRecordMutationHookResult = ReturnType<typeof useCreateUserRecordMutation>;
export type CreateUserRecordMutationResult = Apollo.MutationResult<CreateUserRecordMutation>;
export type CreateUserRecordMutationOptions = Apollo.BaseMutationOptions<CreateUserRecordMutation, CreateUserRecordMutationVariables>;
export const DeleteBlogDocument = gql`
    mutation deleteBlog($id: String!) {
  deleteBlog(id: $id) {
    error {
      success
      message
    }
  }
}
    `;
export type DeleteBlogMutationFn = Apollo.MutationFunction<DeleteBlogMutation, DeleteBlogMutationVariables>;

/**
 * __useDeleteBlogMutation__
 *
 * To run a mutation, you first call `useDeleteBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogMutation, { data, loading, error }] = useDeleteBlogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlogMutation, DeleteBlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlogMutation, DeleteBlogMutationVariables>(DeleteBlogDocument, options);
      }
export type DeleteBlogMutationHookResult = ReturnType<typeof useDeleteBlogMutation>;
export type DeleteBlogMutationResult = Apollo.MutationResult<DeleteBlogMutation>;
export type DeleteBlogMutationOptions = Apollo.BaseMutationOptions<DeleteBlogMutation, DeleteBlogMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($id: String!) {
  deletePost(id: $id)
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(uid: $id) {
    errors {
      success
      message
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const LoginAdminDocument = gql`
    mutation LoginAdmin($email: String!, $password: String!) {
  loginAdmin(options: {email: $email, password: $password}) {
    errors {
      field
      message
    }
    user {
      email
      uid
    }
  }
}
    `;
export type LoginAdminMutationFn = Apollo.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: Apollo.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, options);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout($token: String!) {
  logout(token: $token)
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ToggleDisabledDocument = gql`
    mutation ToggleDisabled($uids: ToggleDisabledUserRecord!) {
  toggleDisabled(options: $uids) {
    errors {
      message
      success
    }
    users {
      uid
      email
      disabled
    }
  }
}
    `;
export type ToggleDisabledMutationFn = Apollo.MutationFunction<ToggleDisabledMutation, ToggleDisabledMutationVariables>;

/**
 * __useToggleDisabledMutation__
 *
 * To run a mutation, you first call `useToggleDisabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDisabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDisabledMutation, { data, loading, error }] = useToggleDisabledMutation({
 *   variables: {
 *      uids: // value for 'uids'
 *   },
 * });
 */
export function useToggleDisabledMutation(baseOptions?: Apollo.MutationHookOptions<ToggleDisabledMutation, ToggleDisabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleDisabledMutation, ToggleDisabledMutationVariables>(ToggleDisabledDocument, options);
      }
export type ToggleDisabledMutationHookResult = ReturnType<typeof useToggleDisabledMutation>;
export type ToggleDisabledMutationResult = Apollo.MutationResult<ToggleDisabledMutation>;
export type ToggleDisabledMutationOptions = Apollo.BaseMutationOptions<ToggleDisabledMutation, ToggleDisabledMutationVariables>;
export const UpdateBlogDocument = gql`
    mutation updateBlog($id: String!, $title: String!, $blog_picture: String!, $content: String!) {
  updateBlog(blog: {id: $id, title: $title, blog_picture: $blog_picture, content: $content}) {
    error {
      success
      message
    }
  }
}
    `;
export type UpdateBlogMutationFn = Apollo.MutationFunction<UpdateBlogMutation, UpdateBlogMutationVariables>;

/**
 * __useUpdateBlogMutation__
 *
 * To run a mutation, you first call `useUpdateBlogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogMutation, { data, loading, error }] = useUpdateBlogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      blog_picture: // value for 'blog_picture'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateBlogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlogMutation, UpdateBlogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlogMutation, UpdateBlogMutationVariables>(UpdateBlogDocument, options);
      }
export type UpdateBlogMutationHookResult = ReturnType<typeof useUpdateBlogMutation>;
export type UpdateBlogMutationResult = Apollo.MutationResult<UpdateBlogMutation>;
export type UpdateBlogMutationOptions = Apollo.BaseMutationOptions<UpdateBlogMutation, UpdateBlogMutationVariables>;
export const UpdateDisabledPropertyDocument = gql`
    mutation UpdateDisabledProperty($inputs: UpdateDisableInput!) {
  updateDisabledProperty(inputs: $inputs) {
    user {
      uid
      email
      username
      name
      type
      disabled
    }
  }
}
    `;
export type UpdateDisabledPropertyMutationFn = Apollo.MutationFunction<UpdateDisabledPropertyMutation, UpdateDisabledPropertyMutationVariables>;

/**
 * __useUpdateDisabledPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateDisabledPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisabledPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisabledPropertyMutation, { data, loading, error }] = useUpdateDisabledPropertyMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateDisabledPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisabledPropertyMutation, UpdateDisabledPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisabledPropertyMutation, UpdateDisabledPropertyMutationVariables>(UpdateDisabledPropertyDocument, options);
      }
export type UpdateDisabledPropertyMutationHookResult = ReturnType<typeof useUpdateDisabledPropertyMutation>;
export type UpdateDisabledPropertyMutationResult = Apollo.MutationResult<UpdateDisabledPropertyMutation>;
export type UpdateDisabledPropertyMutationOptions = Apollo.BaseMutationOptions<UpdateDisabledPropertyMutation, UpdateDisabledPropertyMutationVariables>;
export const UpdateFeaturedDocument = gql`
    mutation updateFeatured($id: String!, $isFeatured: Boolean!) {
  updateFeatured(post: {id: $id, isFeatured: $isFeatured}) {
    errors {
      success
      message
    }
  }
}
    `;
export type UpdateFeaturedMutationFn = Apollo.MutationFunction<UpdateFeaturedMutation, UpdateFeaturedMutationVariables>;

/**
 * __useUpdateFeaturedMutation__
 *
 * To run a mutation, you first call `useUpdateFeaturedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeaturedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeaturedMutation, { data, loading, error }] = useUpdateFeaturedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isFeatured: // value for 'isFeatured'
 *   },
 * });
 */
export function useUpdateFeaturedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeaturedMutation, UpdateFeaturedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeaturedMutation, UpdateFeaturedMutationVariables>(UpdateFeaturedDocument, options);
      }
export type UpdateFeaturedMutationHookResult = ReturnType<typeof useUpdateFeaturedMutation>;
export type UpdateFeaturedMutationResult = Apollo.MutationResult<UpdateFeaturedMutation>;
export type UpdateFeaturedMutationOptions = Apollo.BaseMutationOptions<UpdateFeaturedMutation, UpdateFeaturedMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($args: UpdatePostArgs!) {
  updatePost(args: $args) {
    errors {
      field
      message
    }
    post {
      id
      website
      instagram
      contact
      title
      address
      comment
      imageURL
      location
      address
      categories
      openingHours
      isFeatured
      featuredPriority
    }
  }
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($properties: UpdateUserInput!) {
  updateUser(properties: $properties) {
    user {
      id
      uid
      email
      name
      username
      location
      phone
      profilePicture
      profilePictureBKG
      dob
      relationshipStatus
      gender
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetActiveUsersDocument = gql`
    query GetActiveUsers($filter: FilterByDate!) {
  getActiveUsers(filter: {filter: $filter}) {
    timeSeriesData {
      x
      y
    }
    errors {
      success
      message
    }
  }
}
    `;

/**
 * __useGetActiveUsersQuery__
 *
 * To run a query within a React component, call `useGetActiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetActiveUsersQuery(baseOptions: Apollo.QueryHookOptions<GetActiveUsersQuery, GetActiveUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveUsersQuery, GetActiveUsersQueryVariables>(GetActiveUsersDocument, options);
      }
export function useGetActiveUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveUsersQuery, GetActiveUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveUsersQuery, GetActiveUsersQueryVariables>(GetActiveUsersDocument, options);
        }
export type GetActiveUsersQueryHookResult = ReturnType<typeof useGetActiveUsersQuery>;
export type GetActiveUsersLazyQueryHookResult = ReturnType<typeof useGetActiveUsersLazyQuery>;
export type GetActiveUsersQueryResult = Apollo.QueryResult<GetActiveUsersQuery, GetActiveUsersQueryVariables>;
export const GetBlogsDocument = gql`
    query getBlogs {
  getAllBlogs {
    blogs {
      id
      blog_picture
      title
      content
      created_at
    }
  }
}
    `;

/**
 * __useGetBlogsQuery__
 *
 * To run a query within a React component, call `useGetBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlogsQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogsQuery, GetBlogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogsQuery, GetBlogsQueryVariables>(GetBlogsDocument, options);
      }
export function useGetBlogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogsQuery, GetBlogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogsQuery, GetBlogsQueryVariables>(GetBlogsDocument, options);
        }
export type GetBlogsQueryHookResult = ReturnType<typeof useGetBlogsQuery>;
export type GetBlogsLazyQueryHookResult = ReturnType<typeof useGetBlogsLazyQuery>;
export type GetBlogsQueryResult = Apollo.QueryResult<GetBlogsQuery, GetBlogsQueryVariables>;
export const GetAllFeaturedPostsDocument = gql`
    query GetAllFeaturedPosts {
  getAllFeaturedPosts {
    post {
      id
      website
      title
      address
      comment
      imageURL
      author
      isFeatured
      featuredPriority
    }
    user {
      id
      email
    }
  }
}
    `;

/**
 * __useGetAllFeaturedPostsQuery__
 *
 * To run a query within a React component, call `useGetAllFeaturedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFeaturedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFeaturedPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFeaturedPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllFeaturedPostsQuery, GetAllFeaturedPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFeaturedPostsQuery, GetAllFeaturedPostsQueryVariables>(GetAllFeaturedPostsDocument, options);
      }
export function useGetAllFeaturedPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFeaturedPostsQuery, GetAllFeaturedPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFeaturedPostsQuery, GetAllFeaturedPostsQueryVariables>(GetAllFeaturedPostsDocument, options);
        }
export type GetAllFeaturedPostsQueryHookResult = ReturnType<typeof useGetAllFeaturedPostsQuery>;
export type GetAllFeaturedPostsLazyQueryHookResult = ReturnType<typeof useGetAllFeaturedPostsLazyQuery>;
export type GetAllFeaturedPostsQueryResult = Apollo.QueryResult<GetAllFeaturedPostsQuery, GetAllFeaturedPostsQueryVariables>;
export const GetAllPostsDocument = gql`
    query GetAllPosts {
  getAllPosts {
    post {
      id
      website
      title
      address
      comment
      imageURL
      author
      isFeatured
      featuredPriority
    }
    user {
      id
      email
    }
  }
}
    `;

/**
 * __useGetAllPostsQuery__
 *
 * To run a query within a React component, call `useGetAllPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(GetAllPostsDocument, options);
      }
export function useGetAllPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(GetAllPostsDocument, options);
        }
export type GetAllPostsQueryHookResult = ReturnType<typeof useGetAllPostsQuery>;
export type GetAllPostsLazyQueryHookResult = ReturnType<typeof useGetAllPostsLazyQuery>;
export type GetAllPostsQueryResult = Apollo.QueryResult<GetAllPostsQuery, GetAllPostsQueryVariables>;
export const GetAllUsersDocument = gql`
    query getAllUsers {
  users {
    uid
    email
    username
    name
    type
    disabled
    isAdmin
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetBlogDocument = gql`
    query getBlog($id: String!) {
  getBlog(id: $id) {
    blog {
      blog_picture
      title
      content
      created_at
    }
    error {
      success
      message
    }
  }
}
    `;

/**
 * __useGetBlogQuery__
 *
 * To run a query within a React component, call `useGetBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBlogQuery(baseOptions: Apollo.QueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options);
      }
export function useGetBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options);
        }
export type GetBlogQueryHookResult = ReturnType<typeof useGetBlogQuery>;
export type GetBlogLazyQueryHookResult = ReturnType<typeof useGetBlogLazyQuery>;
export type GetBlogQueryResult = Apollo.QueryResult<GetBlogQuery, GetBlogQueryVariables>;
export const GetDashboardDocument = gql`
    query GetDashboard {
  getDashboard {
    totalUsers
    totalPosts
    totalVenues
    newUsers
    timeSeriesData {
      x
      y
    }
    errors {
      message
      success
    }
  }
}
    `;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions?: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const GetPostDocument = gql`
    query GetPost($uid: String!) {
  getPost(uid: $uid) {
    post {
      id
      website
      instagram
      contact
      title
      address
      comment
      imageURL
      location
      address
      categories
      openingHours
      likes {
        id
        likeId
      }
      isFeatured
      featuredPriority
    }
    errors {
      success
      message
    }
  }
}
    `;

/**
 * __useGetPostQuery__
 *
 * To run a query within a React component, call `useGetPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPostQuery(baseOptions: Apollo.QueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
      }
export function useGetPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
        }
export type GetPostQueryHookResult = ReturnType<typeof useGetPostQuery>;
export type GetPostLazyQueryHookResult = ReturnType<typeof useGetPostLazyQuery>;
export type GetPostQueryResult = Apollo.QueryResult<GetPostQuery, GetPostQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($uid: String!) {
  getUser(uid: $uid) {
    user {
      id
      uid
      email
      name
      username
      location
      phone
      profilePicture
      profilePictureBKG
      dob
      relationshipStatus
      gender
      type
    }
    errors {
      success
      message
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserPostDocument = gql`
    query GetUserPost($id: String!) {
  getUserPost(id: $id) {
    posts {
      id
      website
      title
      address
      comment
      imageURL
    }
    errors {
      success
      message
    }
  }
}
    `;

/**
 * __useGetUserPostQuery__
 *
 * To run a query within a React component, call `useGetUserPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserPostQuery(baseOptions: Apollo.QueryHookOptions<GetUserPostQuery, GetUserPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPostQuery, GetUserPostQueryVariables>(GetUserPostDocument, options);
      }
export function useGetUserPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPostQuery, GetUserPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPostQuery, GetUserPostQueryVariables>(GetUserPostDocument, options);
        }
export type GetUserPostQueryHookResult = ReturnType<typeof useGetUserPostQuery>;
export type GetUserPostLazyQueryHookResult = ReturnType<typeof useGetUserPostLazyQuery>;
export type GetUserPostQueryResult = Apollo.QueryResult<GetUserPostQuery, GetUserPostQueryVariables>;
export const GetWishersDocument = gql`
    query GetWishers($id: String!) {
  getWishers(id: $id) {
    users {
      id
      uid
      email
    }
    errors {
      success
      message
    }
  }
}
    `;

/**
 * __useGetWishersQuery__
 *
 * To run a query within a React component, call `useGetWishersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWishersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWishersQuery(baseOptions: Apollo.QueryHookOptions<GetWishersQuery, GetWishersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWishersQuery, GetWishersQueryVariables>(GetWishersDocument, options);
      }
export function useGetWishersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWishersQuery, GetWishersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWishersQuery, GetWishersQueryVariables>(GetWishersDocument, options);
        }
export type GetWishersQueryHookResult = ReturnType<typeof useGetWishersQuery>;
export type GetWishersLazyQueryHookResult = ReturnType<typeof useGetWishersLazyQuery>;
export type GetWishersQueryResult = Apollo.QueryResult<GetWishersQuery, GetWishersQueryVariables>;
export const MeDocument = gql`
    query Me($token: String!) {
  me(token: $token) {
    uid
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMeQuery(baseOptions: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserRecordFindByUidDocument = gql`
    query UserRecordFindByUid($uid: String!) {
  userRecordFindByUid(options: {uid: $uid}) {
    errors {
      field
      message
    }
    user {
      email
      disabled
    }
  }
}
    `;

/**
 * __useUserRecordFindByUidQuery__
 *
 * To run a query within a React component, call `useUserRecordFindByUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRecordFindByUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRecordFindByUidQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useUserRecordFindByUidQuery(baseOptions: Apollo.QueryHookOptions<UserRecordFindByUidQuery, UserRecordFindByUidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRecordFindByUidQuery, UserRecordFindByUidQueryVariables>(UserRecordFindByUidDocument, options);
      }
export function useUserRecordFindByUidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRecordFindByUidQuery, UserRecordFindByUidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRecordFindByUidQuery, UserRecordFindByUidQueryVariables>(UserRecordFindByUidDocument, options);
        }
export type UserRecordFindByUidQueryHookResult = ReturnType<typeof useUserRecordFindByUidQuery>;
export type UserRecordFindByUidLazyQueryHookResult = ReturnType<typeof useUserRecordFindByUidLazyQuery>;
export type UserRecordFindByUidQueryResult = Apollo.QueryResult<UserRecordFindByUidQuery, UserRecordFindByUidQueryVariables>;
export const UserRecordsDocument = gql`
    query UserRecords {
  userRecords {
    uid
    photoUrl
    email
    disabled
    metadata {
      creationTime
    }
    providerData {
      uid
      providerId
    }
    passwordHash
    user {
      uid
      email
      type
    }
  }
}
    `;

/**
 * __useUserRecordsQuery__
 *
 * To run a query within a React component, call `useUserRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRecordsQuery(baseOptions?: Apollo.QueryHookOptions<UserRecordsQuery, UserRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRecordsQuery, UserRecordsQueryVariables>(UserRecordsDocument, options);
      }
export function useUserRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRecordsQuery, UserRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRecordsQuery, UserRecordsQueryVariables>(UserRecordsDocument, options);
        }
export type UserRecordsQueryHookResult = ReturnType<typeof useUserRecordsQuery>;
export type UserRecordsLazyQueryHookResult = ReturnType<typeof useUserRecordsLazyQuery>;
export type UserRecordsQueryResult = Apollo.QueryResult<UserRecordsQuery, UserRecordsQueryVariables>;