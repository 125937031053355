import React from 'react'

export interface PushNotificationBodyContentProps {
    title: string,
    content: string
}

export const PushNotificationBodyContent: React.FC<PushNotificationBodyContentProps> = ({ title, content }) => {
  return (
    <div className="ml-3 text-sm font-normal">
      <h4 className="text-sm font-semibold text-gray-900 dark:text-white">
        {title}
      </h4>
      <div className="text-sm font-normal">{content}</div>
      {/* <span className="text-xs font-medium text-blue-600 dark:text-blue-500">
          {time}
        </span> */}
    </div>
  )
}
