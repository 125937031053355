import Cookie from "js-cookie";
export const setToken = (token: string) => Cookie.set("oToken", token);
export const setUser = (id: string) => Cookie.set("uID", id);
export const isLoggedIn = () => Cookie.get("oToken");

export const logout = async () => {
    setToken("");
    setUser("");
    window.location.href = "/login";
};
