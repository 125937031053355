import { createMachine, assign, State, Interpreter } from 'xstate'
import { createContext } from 'react'
import { isLoggedIn, setToken, setUser } from '../utils/auth'
import {
  UserRecord,
  MeDocument,
  LogoutDocument,
} from '../generated/graphql'
import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_URI,
  cache: new InMemoryCache(),
})

console.log('[DEBUG] uri', process.env.REACT_APP_APOLLO_URI)

// type MachineContextType = {service: Interpreter<AppMachineContext, AppMachineState>} | undefined;
// export const MachineContext = createContext(null as any);
// export type MachineContextType = [
//   State<AppMachineContext, AppMachineEventType>,
//   Interpreter<AppMachineContext, any, AppMachineEventType>['send'],
//   Interpreter<AppMachineContext, any, AppMachineEventType>,
// ]
// export type TestType = [string, number]
export const MachineContext = createContext<any>([
  {} as State<AppMachineContext, AppMachineEventType>,
  ((() => {}) as any) as Interpreter<
    AppMachineContext,
    any,
    AppMachineEventType
  >['send'],
  {} as Interpreter<AppMachineContext, any, AppMachineEventType>,
])

export interface ChatNotificationContext {
  id?: string
  name?: string
  content?: string
  image?: string
  attachment?: {
    title: string
    location: string
    image: string
  }
}

interface AppMachineContext {
  user?: UserRecord
  chat?: ChatNotificationContext
  chatCount?: number
}

type AppMachineEventType =
  | { type: 'FETCH_USER' }
  | { type: 'EMAIL_INPUT_CHANGED' }
  | { type: 'PASSWORD_INPUT_CHANGED' }
  | { type: 'LOGOUT' }

interface AppMachineState {
  value: 'idle'
  context: AppMachineContext
}

export const appMachine = createMachine<
  AppMachineContext,
  AppMachineEventType,
  AppMachineState
>(
  {
    id: 'app',
    initial: 'init',
    context: {
      user: undefined,
      chat: undefined,
      chatCount: 0,
    },
    states: {
      init: {},
      fetching: {
        invoke: {
          src: 'getMe',
          onDone: {
            actions: `setUser`,
            target: `success`,
          },
          onError: {
            actions: `clearUser`,
            target: `failure`,
          },
        },
      },
      success: {},
      failure: {},
      logout: {
        invoke: {
          src: 'logout',
          onDone: {
            actions: `clearUser`,
            target: `logoutSuccess`,
          },
          onError: 'logoutFailure',
        },
      },
      logoutSuccess: {},
      logoutFailure: {},
    },
    on: {
      FETCH_USER: [{ cond: `isLoggedIn`, target: 'fetching' }],
      LOGOUT: [{ target: 'logout' }],
    },
  },
  {
    actions: {
      setUser: assign<AppMachineContext, any>((_, event) => {
        return { user: event.data }
      }) as any,
      clearUser: assign<AppMachineContext, any>(() => {
        setToken('')
        setUser('')
        return { user: undefined }
      }) as any,
      setChatCount: assign<AppMachineContext, any>((_, event) => ({
        chatCount: event.data,
      })),
    },
    guards: {
      isLoggedIn: () => (isLoggedIn()?.length ? true : false),
    },
    services: {
      getMe: () => getMe(),
      logout: () => logout(),
    },
  },
)

const getMe = async () => {
  try {
    const token = isLoggedIn()
    const { data, errors } = await client.query({
      variables: { token: token },
      query: MeDocument,
      errorPolicy: 'all',
    })

    if (errors) {
      throw errors
    }

    const user: UserRecord = data.me
    return user
  } catch (error) {
    throw error
  }
}

const logout = async () => {
  try {
    const token = isLoggedIn()
    const { errors } = await client.mutate({
      variables: { token: token },
      mutation: LogoutDocument,
      errorPolicy: 'all',
    })

    if (errors) {
      throw errors
    }

    setToken('')
    setUser('')

    return true
  } catch (error) {
    throw error
  }
}
