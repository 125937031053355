import React from 'react'
import { IconBaseProps } from 'react-icons'

export interface PushNotificationBodyImageProps {
  photo?: string | undefined
  title: string
  icon: IconBaseProps
}

const PushNotificationBodyImage: React.FC<PushNotificationBodyImageProps> = ({
  photo,
  title,
  icon
}) => {
  return (
    <div className="relative inline-block shrink-0">
      {photo ? (
        <>
          <img className="w-12 h-12 rounded-full" src={photo} alt={title} />
          <span className="absolute bottom-0 right-0 inline-flex items-center justify-center w-6 h-6 bg-blue-600 rounded-full">
            {icon}
          </span>
        </>
      ) : (
        icon
      )}
    </div>
  )
}

export default PushNotificationBodyImage
