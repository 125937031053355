import React, { Suspense, useEffect, lazy, useState } from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { appMachine, MachineContext } from './states'
import { useMachine } from '@xstate/react'
import { FirebaseProvider } from './firebase'
import { auth, firestore } from './firebase'
import firebase from 'firebase/app'
import { client } from './graphql/client'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PushNotification from './layouts/PushNotification'
import useSound from 'use-sound'
import Cookies from 'js-cookie'
import { HiChatAlt } from 'react-icons/hi'
const RootContent = lazy(() => import('./components/Admin/RootContent'))

const Loading = () => {
  return <p>Loading....</p>
}
const App = () => {
  const [current, send, service] = useMachine(appMachine, { devTools: true })
  const [logged, setLogged] = useState(false)
  const [play] = useSound('/misc/push.mp3')

  const delivered = (id: string) => {
    firebase.firestore().collection('notifications').doc(id).update({
      delivered: true,
    })
  }

  useEffect(() => {
    if (current.matches('init')) {
      send('FETCH_USER')
    }
  }, [current, send])

  useEffect(() => {
    if (Cookies.get('uID')) {
      const unsubscribe = firebase
        .firestore()
        .collection('notifications')
        .where('user_id', '==', Cookies.get('uID'))
        .where('delivered', '==', false)
        .where('type', '==', 'chat')
        .orderBy('createdAt', 'asc')
        .onSnapshot(
          (notifications: any) => {
            notifications.docs.forEach((notification: any) => {
              const __notif = notification.data()
              const user = __notif.data
              play()
              toast(
                <PushNotification
                  pushNotificationBodyImageProps={{
                    photo: user.profilePicture
                      ? user.profilePicture
                      : '/svgs/male-avatar.svg',
                    title: user.name ? user.name : user.displayName,
                    icon: <HiChatAlt className="h-4 w-4 text-white" />,
                  }}
                  pushNotificationBodyContentProps={{
                    title: user.name ? user.name : user.displayName,
                    content: __notif.message,
                  }}
                />,
                {
                  position: 'bottom-right',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  closeButton: false,
                  onClose: () => {
                    delivered(notification.id)
                  },
                },
              )
            })
          },
          (error: firebase.firestore.FirestoreError) => {
            console.error(error)
          },
        )
      return () => {
        unsubscribe()
      }
    }
  }, [send, logged, setLogged, play])

  return current.matches('fetching') ? (
    <Loading />
  ) : (
    <Suspense fallback={<Loading />}>
      <FirebaseProvider.Provider value={{ auth, firestore, firebase }}>
        <MachineContext.Provider value={[current, send, service]}>
          <ApolloProvider client={client}>
            <Router>
              <RootContent />
              <ToastContainer />
            </Router>
          </ApolloProvider>
        </MachineContext.Provider>
      </FirebaseProvider.Provider>
    </Suspense>
  )
}

export default App
